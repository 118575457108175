:root {
  --light-blue: #b5c8f465;
  --dark-blue: #233564;
  --orange: rgb(244, 175, 27);
  --dark-text: #233564;
  --light-text: #b5c8f4;
}

.text-dark {
  color: var(--dark-text);
}

.bg-dark {
  background-color: var(--dark-blue);
}

.text-orange {
  color: var(--orange);
}

.bg-orange {
  background-color: var(--orange);
}

.border-dark {
  border-color: var(--dark-text);
}

.border-orange {
  border-color: var(--orange);
}

* {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.animate-slide-left {
  -webkit-animation: slide 1.3s forwards;
  animation: slide 1.3s forwards;
}

@-webkit-keyframes slide {
  100% {
    right: 0;
  }
}

@keyframes slide {
  100% {
    right: 0;
  }
}

.animate-shake {
  animation: shake 0.5s;
  animation-iteration-count: 5;
  animation-delay: 2s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px) {
  .z-neg-10 {
    z-index: -10;
  }
}

.top-neg-400 {
  top: -450%;
}

.animate-down {
  transition: top 0.5s ease-out;
}

.main {
  background: rgb(68, 110, 224);
}

.dark-text {
  color: var(--dark-text);
}

/* contact page */
.contact-header {
  position: relative;
  margin-bottom: 50px;
}
.contact-header h1 {
  color: var(--dark-text);
  font-weight: bold;
}
.contact-option {
  background: #b5c8f477;
  height: 250px;
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 5px;
  z-index: 2;
  position: relative;
}
.contact-option i {
  color: rgba(128, 163, 204, 0.8);
}
.contact-option p {
  font-size: 25px;
  font-weight: 500;
}
.contact-option span {
  background: #e8520dc4;
  color: white;
  padding: 5px 15px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  cursor: pointer;
}
.contact-option span:hover {
  background-color: #af420fc4;
}
.contact-us-image {
  position: absolute;
  top: 120px;
  right: 30px;
  z-index: 1;
  width: 150px;
  height: 150px;
}

/* popper classes */
.popperTooltip {
  background: #333;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  width: auto;
  text-align: center;
  z-index: 3;
}
.popperTooltip {
  /* ... */
  display: none;
}

.popperTooltip[data-show] {
  display: block;
}
.popperArrow,
.popperArrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

.popperArrow::before {
  content: "";
  transform: rotate(45deg);
  background: #333;
}

.popperTooltip[data-popper-placement^="top"] > .popperArrow {
  bottom: -4px;
}

.popperTooltip[data-popper-placement^="bottom"] > .popperArrow {
  top: -4px;
}

.popperTooltip[data-popper-placement^="left"] > .popperArrow {
  right: -4px;
}

.popperTooltip[data-popper-placement^="right"] > .popperArrow {
  left: -4px;
}
.MainUserView {
  position: relative;
  height: auto;
  height: 300px;
  width: 180px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 3px #0000003d;
  padding: 5px;
}
.MainUserStatusView {
  position: absolute;
  height: auto;
  width: auto;
  max-height: 60px;
  max-width: 60px;
  margin-right: 0;
  right: 10px;
}
.MainUserImageView {
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  top: 60px;
  overflow: hidden;
}
.UserImage {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: inline-block;
}
.user-option-div {
  position: relative;
  text-align: center;
}
.user-options {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: rgba(20, 21, 48, 0.8);
  box-shadow: inset 0 0 16px 9px rgb(0 0 0 / 25%);
  max-width: 130px;
  width: auto;
}
.br-1-white {
  border-right: 1px white solid;
  height: 100%;
}
.bl-1-white {
  border-left: 1px white solid;
  height: 100%;
}
.bt-1-white {
  border-top: 1px solid white;
  height: 100%;
  padding-top: 20px;
}
.UserOutline {
  height: 100%;
  width: 100%;
}
.UserLogo {
  width: 93%;
  height: auto;
  max-width: 130px;
  margin-left: auto;
  margin-right: auto;
}

.user-availability-msg {
  position: absolute;
  top: 10px;
  width: auto;
  max-width: 100%;
  background: #f4af1b;
  z-index: 25;
  color: white;
  padding: 3px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: 1s;
}
.user-availability-msg > p {
  margin: 0;
}
.hvr-icon-grow {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-grow .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  cursor: pointer;
}
.hvr-icon-grow:hover .hvr-icon,
.hvr-icon-grow:focus .hvr-icon,
.hvr-icon-grow:active .hvr-icon {
  -webkit-transform: scale(1.3) translateZ(0);
  transform: scale(1.3) translateZ(0);
}
.fade-in {
  animation: fadeIn 0.5s ease;
  -webkit-animation: fadeIn 0.5s ease;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 1280px) {
  .xl-min-h-80vh {
    min-height: 80vh;
  }
}
